<template>
  <div class="middle slidebottom">
    <el-row :gutter="20">
      <el-col :span="12">

        <!-- 活动通知 轮播图-->
        <el-row class="middle-content">
          <el-col :span="24">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="2000"
              controls
              indicators
              background="#ababab"
              img-width="800"
              img-height="800"
              style="text-shadow: 1px 1px 2px #333;"
            >
              <!-- Text slides with image -->
              <div v-for="(item, index) in latestArticles" @click="goToArticle(item?.id)">
                <b-carousel-slide
                  :key="index"
                  :text="item?.title"
                  :img-src="websiteInfo[0]?.url+item?.cover"
                  style="cursor:pointer"
                />
              </div>
            </b-carousel>
          </el-col>
        </el-row>
      </el-col>
      <!--      协会动态-->
      <el-col :span="12" style="">
        <el-row>

          <el-row
            style="
                      height: 2.5vw;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
          >
            <el-col
              :span="18"
              style="
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: start;
                      "
            >
              <div style="background: #009173; width: 0.5vw; height: 2vw" />
              <div class="active-notify" @mouseenter="active = 0">
                <nuxt-link :to="localePath('/submenu/tzgg')">
                  <div class="active-title">
                    {{ $t('tzgg') }}
                  </div>
                </nuxt-link>
              </div>
              <div class="active" @mouseenter="active = 1">
                <nuxt-link :to="localePath('/submenu/hydt')">
                  <div class="active-title">{{ $t('hydt') }}
                  </div>
                </nuxt-link>
              </div>
              <div class="active" @mouseenter="active = 2">
                <nuxt-link :to="localePath('/submenu/xhdt')">
                  <div class="active-title">{{ $t('xhdt') }}
                  </div>
                </nuxt-link>
              </div>
             <!-- <div class="active" @mouseenter="active = 3">
                <nuxt-link :to="localePath('/submenu/hydt1')">
                  <div class="active-title">{{ $t('hydt1') }}
                  </div>
                </nuxt-link>
              </div> -->
              <div class="active" @mouseenter="active = 4">
                <nuxt-link :to="localePath('/submenu/zxhd')">
                  <div class="active-title">
                    {{ $t('zxhd') }}
                  </div>
                </nuxt-link>
              </div>

            </el-col>
            <el-col :span="6" align="right">
              <nuxt-link :to="localePath(moreLink)">
                <div style="height: 1.25vw; font-size: 1vw">{{ $t('indexMore') }}></div>
              </nuxt-link>
            </el-col>
          </el-row>
          <!--         内容-->
          <el-row class="middle-content">
            <el-col v-if="active == 0" :span="24">
              <el-empty v-if="articlesTzggData.length === 0" :description="$t('none')" />
              <el-row v-for="(item, index) in articlesTzggData" v-else :key="index">
                <el-col :span="24" class="content-row">
                  <!--                开始的图标-->
                  <div
                    style="
                      background: #009173;
                      height: 0.5vw;
                      width: 0.5vw;
                      border-radius: 90%;
                      display: inline-block;
                      margin-right: 1vw;
                    "
                  />
                  <!--                `/submenu/${code}/subarticle/${article.id}-->
                  <nuxt-link :to="localePath(`/submenu/tzgg/subarticle/${item.id}`)">
                    <!--                中间的文字-->
                    <div style="font-size: 1vw">{{ truncateTitle(item.title) }}
                    </div>
                  </nuxt-link>
                  <!--                最后的时间-->
                  <i
                    style="margin-left: auto; color: #999; padding-left: 1vw;font-size: 1vw;font-style: italic;"
                  >[{{ item.time }}]</i>
                </el-col>
              </el-row>

            </el-col>
            <el-col v-if="active === 1" :span="24">
              <el-empty v-if="articlesHydtData.length === 0" :description="$t('none')" />
              <el-row v-for="(item, index) in articlesHydtData" v-else :key="index" class="content-row">
                <!--                开始的图标-->
                <div
                  style="
                   background: #009173;
                   height: 0.5vw;
                   width: 0.5vw;
                   border-radius: 90%;
                   margin-right: 1vw;
                 "
                />
                <!--                中间的文字-->
                <nuxt-link :to="localePath(`/submenu/hydt/subarticle/${item.id}`)">
                  <div style="font-size: 1vw">{{ truncateTitle(item.title) }}
                  </div>
                </nuxt-link>
                <!--                最后的时间-->
                <i
                  style="margin-left: auto; color: #999; padding-left: 1vw;font-size: 1vw;font-style: italic;"
                >[{{ item.time }}]</i>
              </el-row>

            </el-col>

            <el-col v-if="active === 2" :span="24">
              <el-empty v-if="articlesXhdtData.length === 0" :description="$t('none')" />
              <el-row v-for="(item, index) in articlesXhdtData" v-else :key="index" class="content-row">
                <!--                开始的图标-->
                <div
                  style="
                   background: #009173;
                   height: 0.5vw;
                   width: 0.5vw;
                   border-radius: 90%;
                   margin-right: 1vw;
                 "
                />
                <!--                中间的文字-->
                <nuxt-link :to="localePath(`/submenu/xhdt/subarticle/${item.id}`)">
                  <div style="font-size: 1vw">{{ truncateTitle(item.title) }}
                  </div>
                </nuxt-link>
                <!--                最后的时间-->
                <i
                  style="margin-left: auto; color: #999; padding-left: 1vw;font-size: 1vw;font-style: italic;"
                >[{{ item.time }}]</i>
              </el-row>
            </el-col>
            <el-col v-if="active === 3" :span="24">
              <el-empty v-if="articlesMhydtData.length === 0" :description="$t('none')" />
              <el-row v-for="(item, index) in articlesMhydtData" v-else :key="index" class="content-row">
                <!--                开始的图标-->
                <div
                  style="
                   background: #009173;
                   height: 0.5vw;
                   width: 0.5vw;
                   border-radius: 90%;
                   margin-right: 1vw;
                 "
                />
                <!--                中间的文字-->
                <nuxt-link :to="localePath(`/submenu/hydt1/subarticle/${item.id}`)">
                  <div style="font-size: 1vw">{{ truncateTitle(item.title) }}
                  </div>
                </nuxt-link>
                <!--                最后的时间-->
                <i
                  style="margin-left: auto; color: #999; padding-left: 1vw;font-size: 1vw;font-style: italic;"
                >[{{ item.time }}]</i>
              </el-row>
            </el-col>
            <el-col v-if="active == 4" :span="24">
              <el-empty v-if="articlesZxhdData.length === 0" :description="$t('none')" />
              <el-row v-for="(item, index) in articlesZxhdData" v-else :key="index">
                <el-col :span="24" class="content-row">
                  <!--                开始的图标-->
                  <div
                    style="
                      background: #009173;
                      height: 0.5vw;
                      width: 0.5vw;
                      border-radius: 90%;
                      display: inline-block;
                      margin-right: 1vw;
                    "
                  />
                  <!--                `/submenu/${code}/subarticle/${article.id}-->
                  <nuxt-link :to="localePath(`/submenu/zxhd/subarticle/${item.id}`)">
                    <!--                中间的文字-->
                    <div style="font-size: 1vw">{{ truncateTitle(item.title) }}
                    </div>
                  </nuxt-link>
                  <!--                最后的时间-->
                  <i
                    style="    margin-left: auto; color: #999; padding-left: 1vw;font-size: 1vw;font-style: italic;"
                  >[{{ item.time }}]</i>
                </el-col>
              </el-row>
            </el-col>

          </el-row>

        </el-row>
      </el-col>
    </el-row>

    <!--      专家智库-->
    <el-row :gutter="20">
      <el-col :span="12">
        <!--       标题-->
        <el-row
          style="
            height: 2.5vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-bottom: 0.2vw solid #009173;
          "
        >
          <el-col :span="5" align="left">
            <nuxt-link :to="localePath('/submenu/zjzk')">
              <div
                style="
                  height: 2vw;
                  margin-bottom: -0.5vw;
                  background: #009173;
                  padding: 0.2vw 0vw;
                  text-align: center;
                  font-size: 1vw;
                  color: #fff;
                  font-weight: bold;
                "
              >{{ $t('zjzk') }}
              </div>
            </nuxt-link>
          </el-col>
          <el-col :span="19" align="right">
            <nuxt-link to="/submenu/zjzk">
              <div style="height: 1.25vw; font-size: 1vw;">{{ $t('indexMore') }}></div>
            </nuxt-link>
          </el-col>
        </el-row>
        <!--        主体-->
        <el-row style="height: 25vw; overflow: hidden; display: flex; flex-wrap: wrap">
          <div
            v-for="(item, index) in articlesZjzkData"
            :key="index"
            style="
              flex: 0 0 calc(20% - 1.25vw);
              margin:0.8vw 0.625vw;
              height: 10.8vw;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              transition: transform 0.02s;
              cursor: pointer;
            "
            @mouseenter="handleMouseEnter(item)"
            @mouseleave="handleMouseLeave(item)"
          >
            <div>
              <el-image :src="websiteInfo[0]?.url+item.cover" style=" width: 6vw; height: 7vw" fit="contain" lazy />
              <div style="text-align: center; margin: 0.2vw;font-size: 1vw;overflow: hidden;">{{ item.title }}</div>
            </div>
            <nuxt-link to="/submenu/zjzk">
              <transition name="fade">
                <!-- <div
                  v-if="item.isHovered"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(160,160,160,2.19);
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    /*height:*/
                    box-sizing: border-box;
                  "
                > -->
                <div
                  v-if="item.isHovered"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(10px);
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                    color: white;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                  "
                >
                  <div style="font-size: 0.9vw; padding: 2px">{{ truncateTitle(item.intro,80) }}</div>
                </div>
              </transition>
            </nuxt-link>
          </div>
        </el-row>
      </el-col>

      <el-col :span="12">
        <!--    会员风采-->
        <!--       标题-->
        <el-row
          style="
            height: 2.5vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-bottom: 0.2vw solid #009173;
          "
        >
          <el-col :span="5" align="left">
            <nuxt-link to="/submenu/hyfc">
              <div
                style="
                  height: 2vw;
                  margin-bottom: -0.5vw;
                  background: #009173;
                  padding: 0.2vw 0vw;
                  text-align: center;
                  font-size: 1vw;
                  color: #fff;
                  font-weight: bold;
                "
              >{{ $t('hyfc') }}
              </div>
            </nuxt-link>
          </el-col>
          <el-col :span="19" align="right">
            <nuxt-link to="/submenu/hyfc">
              <div style="height: 1.25vw; font-size: 1vw;">{{ $t('indexMore') }}></div>
            </nuxt-link>
          </el-col>
        </el-row>
        <!--        主体-->
        <el-row style="height: 25vw; overflow: hidden; display: flex; flex-wrap: wrap">
          <nuxt-link
            v-for="(item, index) in latestHyfc"
            :key="index"
            :to="localePath(`/submenu/hyfc/subarticle/${item.id}`)"
            class="member-card"
            style="
              flex: 0 0 calc(20%);
              margin: 0.625vw;
              height: 9.85vw;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              color: inherit; /* Ensure link text inherits color */
            "
          >
            <div style="display: flex; flex-direction: column; align-items: center;  border-radius:50%;">
              <el-image
                :key="index"
                :src="websiteInfo[0]?.url+item.cover"
                style="background-color: rgba(246,248,251,0.02); justify-content: center; display: flex;height: 6vw;width: 6vw"
                fit="contain"
                lazy
              />
              <div
                style="text-align: center; margin-top: 0.8vw;padding:0 0.5vw; font-size: 1vw;
    font-family: PingFang;
    font-weight: 200;
    color: #000000;"
              >{{ truncateTitle(item.title,15) }}
              </div>
            </div>
          </nuxt-link>
        </el-row>

      </el-col>
    </el-row>

    <el-row>
      <!--       标题-->
      <!--      <el-row style="
            height: 2.5vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-bottom: 0.2vw solid #009173;
          ">
        <el-col :span="3" align="left">
          <nuxt-link to="/submenu/hzhb" style="text-decoration: none">
            <div style="
                  height: 2vw;
                  margin-bottom: -0.5vw;
                  background: #009173;
                  padding: 0.2vw 0vw;
                  text-align: center;
                  font-size: 1vw;
                  color: #fff;
                  font-weight: bold;
                ">{{ $t('hzhb') }}
            </div>
          </nuxt-link>
        </el-col>
        <el-col :span="21" align="right">
          <nuxt-link to="/submenu/hzhb">
            <div style="height: 1.25vw; font-size: 1vw;">{{$t('more')}}></div>
          </nuxt-link>
        </el-col>
      </el-row> -->
      <!--        主体-->
      <!--      <el-row style="height: 10vw; overflow: hidden; display: flex; flex-wrap: wrap">
        <div v-for="(item, index) in articlesHzhbData" style="
              flex: 0 0 calc(20% - 10.25vw);
              margin: 0.325vw;
              height: 9vw;
              display: flex;
              flex-direction: column;
            ">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <nuxt-link :to="localePath(`/submenu/hzhb/subarticle/${item.id}`)">
              <el-image :key="index" :src="websiteInfo[0]?.url+item.cover"
                style="background-color: #ffffff; justify-content: center; display: flex;height: 8vw;width: 8vw"
                fit="contain" lazy />
              <div style="text-align: center; margin:auto; font-size: 1vw;">{{ truncateTitle(item.title) }}
              </div>
            </nuxt-link>
          </div>
        </div>
      </el-row> -->
    </el-row>
  </div>
</template>

<script>
// import YCategory from './Category'

import {
  getStore
} from '../../utils/storage'

export default {
  // components: {
  //   YCategory
  // },
  props: {
    articlesData: {
      type: [Object, Array],
      default() {
        return []
      }
    },
    height: {
      type: [String, Number],
      default: 456
    },
    activeDataObj: {
      type: [Object],
      default: null
    },
    categoryList: {
      type: [Object, Array],
      default() {
        return []
      }
    }
  },
  data() {
    return {
      websiteInfo: this.$store.state.websiteInfo,
      currentlocale: 'zhObj',
      num: 0,
      interval: null,
      active: 0,
      isHovered: false,
      slide: 0,
      sliding: null,
      displayedArticles: [],
      latestHyfc: [],
      latestArticles: [],
      articlesHzhbData: [],
      articlesZjzkData: [],
      articlesTzggData: [],
      articlesHydtData: [],
      articlesMhydtData: [],
      articlesXhdtData: [],
      // articlesGjjlData: [],
      // articlesLnhdData: [],
      articlesZxhdData: []
    }
  },
  computed: {
    moreLink() {
      switch (this.active) {
        case 0:
          return '/submenu/tzgg'
        case 1:
          return '/submenu/hydt'
        case 2:
          return '/submenu/xhdt'
        case 3:
          return '/submenu/hydt'
        case 4:
          return '/submenu/zxhd'
        default:
          return '/'
      }
    }
  },
  mounted() {
    this.websiteInfo = this.$store.state.websiteInfo,
    this.getLocale()
    this.$nextTick(() => {
      if (this.activeDataObj) {
        this.resolveData()
      }
    })
  },
  methods: {
    getLocale() {
      this.currentlocale = getStore('currentLocale')
    },
    resolveData() {
      const nativeSlice = Array.prototype.slice

      this.articlesTzggData = nativeSlice.call(this.activeDataObj.articlesTzggData, 0, 6)

      // 协会动态
      this.articlesXhdtData = nativeSlice.call(this.activeDataObj.articlesXhdtData, 0, 6)

      this.articlesHydtData = nativeSlice.call(this.activeDataObj.articlesHydtData, 0, 6)

      this.articlesMhydtData = nativeSlice.call(this.activeDataObj.articlesMhydtData, 0, 6)

      this.latestHyfc = nativeSlice.call(this.activeDataObj.articlesHyfcData, 0, 8)

      this.latestArticles = nativeSlice.call(this.activeDataObj.articlesZxhdData, 0, 8)

      this.articlesHzhbData = nativeSlice.call(this.activeDataObj.articlesHzhbData, 0, 10)

      this.articlesZjzkData = nativeSlice.call(this.activeDataObj.articlesZjzkData, 0, 10)

      this.articlesGjjlData = nativeSlice.call(this.activeDataObj.articlesGjjlData, 0, 6)

      this.articlesLnhdData = nativeSlice.call(this.activeDataObj.articlesLnhdData, 0, 6)

      this.articlesZxhdData = nativeSlice.call(this.activeDataObj.articlesZxhdData, 0, 6)
    },
    goToArticle(id) {
      this.$router.push(this.localePath({
        path: `/submenu/zxhd/subarticle/${id}`
      }))
    },
    truncateTitle(title, maxLength = 30) {
      if (!title || title.trim() === '') return this.currentlocale == 'zhObj' ? '暂无信息' : 'No information available'
      if (title?.length > maxLength) {
        return title?.substring(0, maxLength) + '...'
      }
      return title
    },
    handleMouseEnter(item) {
      this.$set(item, 'isHovered', true)
    },
    handleMouseLeave(item) {
      this.$set(item, 'isHovered', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  /* 设置轮播图片容器的样式 */
  .carousel-inner .carousel-item {
    height: 26.5vw;
    /* 设置轮播图片的高度为视口高度的50% */

  }

  .middle-content {
    // min-height: auto;
    height: 24vw;
  }

  /* 在这里添加媒体查询来调整轮播图的样式 */
  @media (max-width: 768px) {
    .carousel-inner .carousel-item {
      height: 12vh;
      /* 设置轮播图片的高度为视口高度的50% */
    }

    .middle-content {
      min-height: 12vh;
    }
  }

  /* 设置轮播图片的样式 */
  .carousel-inner img {
    width: 100%;
    /* 设置图片宽度为100% */
    height: 100%;
    /* 设置图片高度为100% */
    object-fit: cover;
    /* 确保图片覆盖整个容器，可能会被裁剪 */
  }

  ::v-deep .img-fluid {
    max-width: 100%;
    /* height: auto; */
  }

  ::v-deep .carousel-caption {
    padding-top: 1vw;
  }

  .content-row {
    height: 4vw;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: solid 0.0625vw #ddd;

    &:hover {
      background: #f6f6f6;

      a,
      i {
        color: #0c0c0c !important;
      }
    }
  }

  .active {
    background: #f0f0f0;

    &:hover {
      background: #009173;

      .active-title {
        font-size: 1.25vw;
        //font-weight: bold;
        color: white;
        height: 1.25vw;
        padding: 0 1vw;
      }
    }
  }

  .active-notify {
    font-size: 1.25vw;
    font-weight: bold;
    color: #009173;
    height: 1.1vw;
    padding: 0 1vw;
  }

  .active-title {
    font-size: 1.1vw;
    //font-weight: bold;
    color: #009173;
    height: 1.1vw;
    padding: 0.2vw 0.5vw;
  }

  .middle {
    position: relative;
    width: 93vw; // margin: 1.8125vw 1.8vw 1.5vw 11.9vw;
    margin: 2vw 3.5vw 1.5vw 3.5vw; //margin: 1.8125vw 1.5vw 1.5vw 1.5vw;

    //height: 45.625vw;
    .zxfb {
      display: inline-block;
      float: left;
      width: 7.5vw;
      height: 1.5625vw;
      line-height: 1.25vw;
      text-align: center;
      margin-top: 0.3125vw;
      border-left: 0.3125vw solid #009173;
      font-size: 1.25vw;
      font-weight: bold;
      color: #009173;
    }

    ul {
      float: left;
      height: auto;

      li {
        width: 6.25vw;
        height: 1.875vw;
        line-height: 1.875vw;
        float: left;
        font-size: 1vw;
        border-right: solid 1px #ddd;
        background: #f0f0f0;
        color: #009173;
        font-weight: normal;
        text-align: center;
      }
    }

    .hyhd ul li:hover {
      background: #009173;
      color: white;
    }

    .inform_more {
      float: right;
      cursor: pointer;
      height: 2.5vw;
      line-height: 2.5vw;
      padding-right: 0.625vw;
      position: relative;
      font-size: 0.875vw;
    }

    dd {
      position: absolute;
      top: 2.5vw;

      ol {
        li {
          line-height: 2.1875vw;
          font-size: 1vw;
          width: 100%;
          clear: both;
          height: 2.1875vw;
          line-height: 2.1875vw;
          font-size: 1vw;
          color: #303030;

          span {
            float: left;
            display: inline-block;
            width: 0.25vw;
            height: 0.25vw;
            background: #1261c3;
            margin: 0.9375vw 0.625vw 0 0.625vw;
          }

          i {
            font-style: italic;
            display: inline-block;
            color: #999;
            float: right;
            font-size: 0.75vw;
          }
        }
      }
    }

    .mainxxk_lf1 {
      width: 40.1875vw; //height: 23.125vw;
      //float: left;
      border-bottom: solid 0.125vw #f1f1f1; //margin-left: 0.625vw;

      .hy_text_title {
        width: 100%;
        height: 2.5vw;
        border-bottom: solid 0.1875vw #009173;

        h4 {
          line-height: 2.5vw;
          display: inline-block;
          width: 7.8125vw;
          height: 2.5vw;
          text-align: center;
          background: #009173;
          font-size: 1vw;
          font-weight: bold;
          color: #fff;

          a {
            display: block; //height: 100%;
            color: #fff;
          }
        }

        .banner_more {
          float: right;
          color: #272727;
          //font-size: 0.875vw;
          padding-right: 0.625vw;
          height: 1.875vw;
          line-height: 3.125vw;
        }

        .sanlanl_xin {
          width: 40.1875vw; //height: 18.4375vw;
          float: left;
          background: #009173;
          padding: 0.8125vw 0.625vw;
          filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#cc062eaf, endcolorstr=#cc062eaf);

          ul li {
            width: 5vw; //height: 5vw;
            float: left; //margin-left: 0.9375vw;
            //margin-top: 1.875vw;
            cursor: pointer;

            img {
              //overflow-clip-margin: content-box;
              //overflow: clip;
              background: #009173;
            }
          }
        }
      }
    }

    .hover-row {
      background-color: #207997;
      /* 鼠标悬停时的背景颜色 */
    }

    .hover-box {
      width: 200px;
      height: 200px;
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      transition: background-color 0.3s;
    }

    .hover-box.hovered {
      background-color: lightblue;
    }

    .member-card {
      background-color: white;
      transition: background-color 0.3s;
    }

    .member-card:hover {
      background-color: rgb(229, 225, 225);
    }

    //.mainxxk {
    //  clear: both;
    //  width: 100%;
    //  height: 18.75vw;
    //  .hy_text_title {
    //    width: 100%;
    //    height: 2.5vw;
    //    border-bottom: solid 0.1875vw #009173;
    //    h4 {
    //      line-height: 2.5vw;
    //      display: inline-block;
    //      width: 7.8125vw;
    //      height: 2.5vw;
    //      text-align: center;
    //      background: #009173;
    //      font-size: 1vw;
    //      font-weight: bold;
    //      color: #fff;
    //      a {
    //        display: block; //height: 100%;
    //        color: #fff;
    //      }
    //    }
    //    .banner_more {
    //      float: right;
    //      color: #272727;
    //      font-size: 0.875vw;
    //      padding-right: 0.625vw;
    //      height: 1.875vw;
    //      line-height: 3.125vw;
    //    }
    //  }
    //  .qy_cl {
    //    width: 73.125vw;
    //    ul li img {
    //      width: 9.9375vw;
    //      height: 5.25vw;
    //      float: left;
    //    }
    //    a {
    //      font-size: 0.875vw;
    //    }
    //  }
    //}
  }
</style>
