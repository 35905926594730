<template>
  <div class="index_page">
    <transition name="slide-in" appear>
      <y-header :active="'index'" class="slide" />
    </transition>
    <transition name="slide-in" appear>
      <el-row class="slide">
        <el-col :span="24" style="">
          <b-carousel
            id="carousel-1"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1920"
            img-height="1080"
            style="text-shadow: 1px 1px 2px #333;"
          >
            <!-- Text slides with image -->
            <a
              v-for="(item, index) in carouselData"
              :key="index"
              :href="item?.url"
              class="carousel-a"
            >
              <b-carousel-slide
                :text="item?.title"
                :img-src="item?.cover"
              />
            </a>
          </b-carousel>
        </el-col>
      </el-row>
    </transition>
    <transition name="slidebottom-in" appear  >
      <y-middle
        v-show="isPC"
        :height="366"
        :active-data-obj="activeDataObj"
        :articles-data="articlesXhdtData"
      />
    </transition>
    <transition name="slidebottom-in"   appear>
      <MiddleMobile
        v-show="!isPC"
        :height="366"
        :active-data-obj="activeDataObj"
        :articles-data="articlesXhdtData"
      />
    </transition>
    <y-footer />

  </div>
</template>
<script>
import YHeader from '~/components/common/Header'
import YMiddle from '~/components/common/Middle'
import MiddleMobile from '~/components/common/MiddleMobile'
import YFooter from '~/components/common/Footer'

import { carouselList, getArticles } from '~/api/main.js'

export default {
  components: {
    YHeader,
    YMiddle,
    MiddleMobile,
    YFooter
  },
  async asyncData(context) {
    try {
      const dataObj = {}
      const activeDataObj = {}
      const locale = context.store.state.currentLocale
      const req = { 'headers': { 'cookie': `i18n_redirected=${locale}` }}
      // 轮播图

      console.log('context===================>', req)
      dataObj.carouselData = await carouselList(req)
      // 栏目文章内容
      // 1.协会动态
      console.log("context.store.state.itemcate['xhdt']===================>", context.store.state.itemcate['xhdt'])
      dataObj.articlesXhdtData = await getArticles(context.store.state.itemcate['xhdt'], req)
      console.log("context.store.state.itemcate['xhdt']===================>", context.store.state.itemcate['xhdt'])
      activeDataObj.articlesXhdtData = await getArticles(context.store.state.itemcate['xhdt'], req)
      // 通知公告
      console.log("context.store.state.itemcate['tzgg']===================>", context.store.state.itemcate['tzgg'])
      activeDataObj.articlesTzggData = await getArticles(context.store.state.itemcate['tzgg'], req)
      // 行业动态
      console.log("context.store.state.itemcate['hydt']===================>", context.store.state.itemcate['hydt'])
      activeDataObj.articlesHydtData = await getArticles(context.store.state.itemcate['hydt'], req)
      // 会员动态
      console.log("context.store.state.itemcate['hydt1']===================>", context.store.state.itemcate['hydt1'])
      activeDataObj.articlesMhydtData = await getArticles(context.store.state.itemcate['hydt1'], req)
      // 2.最新活动
      console.log("context.store.state.itemcate['zxhd']===================>", context.store.state.itemcate['zxhd'])
      activeDataObj.articlesZxhdData = await getArticles(context.store.state.itemcate['zxhd'], req)

      // 3.历年活动 del
      console.log("context.store.state.itemcate['lnhd']===================>", context.store.state.itemcate['lnhd'])
      activeDataObj.articlesLnhdData = await getArticles(context.store.state.itemcate['lnhd'], req)

      // 3.国际交流 del
      console.log("context.store.state.itemcate['gjjl']===================>", context.store.state.itemcate['gjjl'])
      activeDataObj.articlesGjjlData = await getArticles(context.store.state.itemcate['gjjl'], req)

      // 专家智库内容
      // 1.专家智库
      console.log("context.store.state.itemcate['zjzk']===================>", context.store.state.itemcate['zjzk'])
      activeDataObj.articlesZjzkData = await getArticles(context.store.state.itemcate['zjzk'], req)

      // 会员内容
      console.log("context.store.state.itemcate['hyfc']===================>", context.store.state.itemcate['hyfc'])
      activeDataObj.articlesHyfcData = await getArticles(context.store.state.itemcate['hyfc'], req)
      // 加载会员信息

      // activeDataObj.articlesHyfcData = await memberList('?page=1&limit=10')
      // 合作伙伴
      console.log("context.store.state.itemcate['hzhb']===================>", context.store.state.itemcate['hzhb'])
      activeDataObj.articlesHzhbData = await getArticles(context.store.state.itemcate['hzhb'], req)

      dataObj.activeDataObj = activeDataObj
      console.log('-------------------', dataObj)
      return dataObj
    } catch (e) {
      // context.error({ message: 'data no found', statusCode: 404 })
    }
  },
  data() {
    return {
      isPC: false,
      websiteInfo: this.$store.state.websiteInfo,
      showMiddle: false
    }
  },
  head() {
    return {
      title: this.$store.state.websiteInfo.name,
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$store.state.websiteInfo.note
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.websiteInfo.note
        }
      ]
    }
  },
  mounted() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.restPwdNotice()
    // 监听滚动事件
    this.watchScroll()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('scroll', this.handleBottomScroll)
  },
  methods: {
    restPwdNotice() {
      if (this.$route.query) {
        const { message } = this.$route.query
        if (message) {
          this.$message.success(message)
        }
      }
    },
    checkDevice() {
      if (typeof window !== 'undefined') {
        this.isPC = window.innerWidth > 768 // 根据需要调整阈值
      }
    },
    watchScroll() {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('scroll', this.handleBottomScroll)
    },
    handleScroll() {
      // 检查页面元素是否进入视口
      const element = this.$el.querySelector('.slide')
      const rect = element.getBoundingClientRect()
      const viewHeight = window.innerHeight || document.documentElement.clientHeight
      const elementTop = rect.top
      const elementBottom = rect.bottom

      if (elementBottom >= 0 && elementTop <= viewHeight) {
        // 元素进入视口，启动动画
        element.classList.add('slide-in-enter-to')
        // 移除监听器，避免重复动画
        window.removeEventListener('scroll', this.handleScroll)
      }
    },
    handleBottomScroll() {
      // 检查页面元素是否进入视口
      const element = this.$el.querySelector('.slidebottom')
      const rect = element.getBoundingClientRect()
      const viewHeight = window.innerHeight || document.documentElement.clientHeight
      const elementTop = rect.top
      const elementBottom = rect.bottom

      if (elementBottom >= 0 && elementTop <= viewHeight) {
        this.showMiddle = true
        // 元素进入视口，启动动画
        element.classList.add('slidebottom-in-enter-to')
        // 移除监听器，避免重复动画
        window.removeEventListener('scroll', this.handleBottomScroll)
      }
    }

  }
}
</script>
<style lang="scss" scoped>
/* 设置轮播图片容器的样式 */
.carousel-inner .carousel-item {
  //height: 10%; /* 设置轮播图片的高度为视口高度的50% */
}
/* 在这里添加媒体查询来调整轮播图的样式 */
@media (max-width: 768px) {
  .carousel-inner .carousel-item {
    //height: 30vh; /* 设置轮播图片的高度为视口高度的50% */
  }
  ::v-deep .carousel-caption {
    display: none;
  }
}
/* 设置轮播图片的样式 */
.carousel-inner img {
  width: 100%; /* 设置图片宽度为100% */
  height: 100%; /* 设置图片高度为100% */
  object-fit: contain!important; /* 确保图片覆盖整个容器，可能会被裁剪 */
}
::v-deep .img-fluid {
  max-width: 100%;
  /* height: auto; */
}

::v-deep .carousel-caption {
  bottom: -1vh!important;
}

.videoAlert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .videoAlertbox {
    position: absolute;
    width: 420px;
    top: 50%;
    left: 50%;
    height: 472px;
    margin: -246px 0 0 -210px;

    .videoAlertImg {
      height: 472px;
      width: 420px;
    }

    .videoAlertClone {
      position: absolute;
      bottom: -40px;
      left: 50%;
      margin-left: -16px;
      width: 32px;
      font-size: 30px;
      height: 32px;
      border-radius: 20px;
    }
  }
}

.index_page {
  display: flex;
  flex-direction: column;
  .clearfix:before,
  .clearfix:after {
    content: "";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .i_content {
    background: #f6f8fb;

    .i_zone {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 0;
    }
  }

  .zone_header {
    .col_block {
      display: inline-block;
      width: 4px;
      height: 24px;
      background: #333;
      position: relative;
      top: 4px;
    }

    .big_text {
      // text-align: center;
      font-size: 24px;
    }

    .small_text {
      // text-align: center;
      font-size: 14px;
      color: #999;
      margin-top: 10px;
    }

    .link_text:hover {
      text-decoration: none;
      color: #333;
    }
  }

  .zone_body {
    margin-top: 25px;
  }

  .course_list {
    li {
      float: left;
      width: 285px;
      height: 222px;
      border-radius: 6px;
      margin: 0px 20px 20px 0px;

      &:nth-child(4n) {
        margin-right: 0px;
      }

      &:hover {
        box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
        transition: all 0.3s;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        background: #fff;
        border-radius: 6px;

        .img_box {
          width: 285px;
          height: 158px;
          position: relative;

          .qizi {
            background: url(../assets/image/activity/qizi.png) no-repeat center;
            position: absolute;
            top: 0;
            left: 10px;
            width: 46px;
            height: 46px;
            color: #fff;
            font-size: 14px;
            text-align: center;
          }

          img {
            width: 285px;
            height: 158px;
            border-radius: 6px 6px 0 0;
          }

          .live_time {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 12px;
            line-height: 16px;
            width: 285px;
            background: rgba(0, 0, 0, 0.2);
            color: #fff;
            padding-bottom: 2px;
          }
        }

        &:hover {
          color: #000;
          text-decoration: none;
        }

        p {
          font-size: 16px;
          margin-top: 5px;
          padding-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }

        .price_box {
          font-size: 16px;
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: red;
        }
      }
    }
  }

  .test_list {
    .test_option {
      float: left;
      width: 520px;
      padding: 18px 25px;
      margin-right: 55px;
      margin-bottom: 20px;
      border-radius: 8px;
      font-size: 14px;
      background-color: #fff;

      &.right_0 {
        margin-right: 0px;
      }
    }

    .iconfont {
      margin-right: 6px;
    }
  }
}
</style>
